import React, { useEffect } from "react";
import calculating from "../src/assets/images/calculating-trans.png";
import airobot from "../src/assets/images/ai-robot-transparent.png";
import "./App.css";
import { Box, Button } from "@mui/material";
import DfMessenger from "./components/DfMessenger";

declare global {
  interface Window {
    clearChat: () => void;
  }
}

function App() {
  const clearChat = () => {
    const dfMessenger = document.querySelector("df-messenger");
    if (dfMessenger) {
      const chatContainer = dfMessenger.shadowRoot
        ?.querySelector("df-messenger-chat")
        ?.shadowRoot?.querySelector(".messages");

      if (chatContainer) {
        chatContainer.innerHTML = "";
      }
    }

    sessionStorage.clear();

    window.location.reload();
  };

  useEffect(() => {
    // Add clearChat function to the window object for debugging
    window.clearChat = clearChat;

    // Add event listener to clear chat button
    const clearChatButton = document.querySelector(".clear-chat");
    if (clearChatButton) {
      clearChatButton.addEventListener("click", clearChat);
    }

    // Cleanup event listener on unmount
    return () => {
      if (clearChatButton) {
        clearChatButton.removeEventListener("click", clearChat);
      }
    };
  }, []);

  return (
    <Box className="App">
      <Box className="color-logo"></Box>

      <Box className="App-header">
        <Box className="hack-logo"></Box>
        <Box className="robot-wrapper">
          <img src={calculating} className="calculating" alt="logo" />
          <img src={airobot} className="robot" />
        </Box>
      </Box>
      <DfMessenger />
      <Button
        className="clear-chat"
        sx={{
          color: "#a8a8a8",
          backgroundColor: "#464646",
          "&:hover": {
            backgroundColor: "rgba(167, 17, 145, 0.5)",
          },
        }}
      >
        Clear Chat
      </Button>
    </Box>
  );
}

export default App;
