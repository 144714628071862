import React, { useEffect } from "react";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "df-messenger": any;
      "df-messenger-chat": any;
    }
  }
}

const DfMessenger: React.FC = () => {
  return (
    <>
      <df-messenger
        project-id="boingo-hackathon"
        agent-id="59d98363-68d6-4ddc-a747-cf1412e33226"
        language-code="en"
        max-query-length="-1"
        class="ai-support"
      >
        <df-messenger-chat chat-title="BoingoSupportAI"></df-messenger-chat>
      </df-messenger>
    </>
  );
};

export default DfMessenger;
